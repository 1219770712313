<template>
  <div>
    <div style="height: 600px;" ref="chart_data"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import moment from "moment"
export default {
  name: "ChartUserGender",
  props: {
    title: {
      type: String,
      default: () => {
        return ""
      }
    },
    seriesName: {
      type: String,
      default: () => {
        return ""
      }
    },
    color: {
      type: Array,
      default: () => {
        return [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
          "#f6efa6",
        ]
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      charts: null
    }
  },
  watch: {
    data() {
      this.reload()
    }
  },
  mounted() {
    this.charts = echarts.init(this.$refs['chart_data'])
  },
  methods: {
    reload() {
      var option = {
        title: {
          text: this.title,
          left: 'center'
        },
        color: this.color,
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: this.seriesName,
            type: 'pie',
            radius: '50%',
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.charts.setOption(option)
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
